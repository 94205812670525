<template>
  <div class="content-box">
    <div class="table">
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="name" label="姓名" >
        </el-table-column>
        <el-table-column prop="phone" label="电话">
        </el-table-column>
        <el-table-column label="问题" prop="title"> </el-table-column>
      </el-table>
      <div class="pagination">
        <pagination
          style="padding: 0"
          class="pages"
          v-show="total > 0"
          :total="total"
          layout="total,  prev, pager, next, jumper"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { MessageList } from "@/common/js/api";
import Pagination from "@/common/Pagination";

export default {
  components: { Pagination },
  data() {
    return {
      tableData: [],
      total: 0, //总条目数
      listQuery: {
        limit: 20,
        page: 1,
      },
    };
  },
  created() {
    this.getList();
   },
  methods: {
    //  咨询列表
    getList() {
      MessageList({
        member_id: JSON.parse(localStorage.getItem("user")).id,
        page: this.listQuery.page,
        limits: this.listQuery.limit,
      })
        .then((res) => {
          console.log(res);
          this.tableData = res.data;
          this.total = res.data.length;
        })
        .catch(() => {});
    },
  },
};
</script>
 
<style lang="less" scoped>

.table {
  padding: 50px 100px 0 43px;
}
.pagination {
  background-color: #f7f4f4;
  padding: 10px 0;
}
</style>